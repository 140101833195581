import { useEffect } from 'react';
import usePersistFn from '../usePersistFn';

function useTimeout(fn, delay) {
  var timerFn = usePersistFn(fn);
  useEffect(function () {
    if (delay === undefined || delay === null) return;
    var timer = setTimeout(function () {
      timerFn();
    }, delay);
    return function () {
      clearTimeout(timer);
    };
  }, [delay, timerFn]);
}

export default useTimeout;