var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

import { useState, useRef, useEffect, useMemo } from 'react';
import { getTargetElement } from '../utils/dom';
export default function useExternal(path, options) {
  var isPath = typeof path === 'string' && path !== '';

  var _a = __read(useState(isPath ? 'loading' : 'unset'), 2),
      status = _a[0],
      setStatus = _a[1];

  var _b = __read(useState(isPath), 2),
      active = _b[0],
      setActive = _b[1];

  var ref = useRef();
  useEffect(function () {
    var _a;

    (_a = ref.current) === null || _a === void 0 ? void 0 : _a.remove();

    if (!isPath || !active) {
      setStatus('unset');
      ref.current = undefined;
      return;
    }

    setStatus('loading'); // Create external element

    var pathname = path.replace(/[|#].*$/, '');

    if ((options === null || options === void 0 ? void 0 : options.type) === 'css' || /(^css!|\.css$)/.test(pathname)) {
      // css
      ref.current = document.createElement('link');
      ref.current.rel = 'stylesheet';
      ref.current.href = path;
      ref.current.media = (options === null || options === void 0 ? void 0 : options.media) || 'all'; // IE9+

      var isLegacyIECss = ('hideFocus' in ref.current); // use preload in IE Edge (to detect load errors)

      if (isLegacyIECss && ref.current.relList) {
        ref.current.rel = 'preload';
        ref.current.as = 'style';
      }

      ref.current.setAttribute('data-status', 'loading');
      document.head.appendChild(ref.current);
    } else if ((options === null || options === void 0 ? void 0 : options.type) === 'js' || /(^js!|\.js$)/.test(pathname)) {
      // javascript
      ref.current = document.createElement('script');
      ref.current.src = path;
      ref.current.async = (options === null || options === void 0 ? void 0 : options.async) === undefined ? true : options === null || options === void 0 ? void 0 : options.async;
      ref.current.setAttribute('data-status', 'loading');
      document.body.appendChild(ref.current);
    } else if ((options === null || options === void 0 ? void 0 : options.type) === 'img' || /(^img!|\.(png|gif|jpg|svg|webp)$)/.test(pathname)) {
      // image
      ref.current = document.createElement('img');
      ref.current.src = path;
      ref.current.setAttribute('data-status', 'loading'); // append to wrapper

      var wrapper = getTargetElement(options === null || options === void 0 ? void 0 : options.target) || document.body;

      if (wrapper) {
        wrapper.appendChild(ref.current);
      }
    } else {
      // do nothing
      console.error("Cannot infer the type of external resource, and please provide a type ('js' | 'css' | 'img'). " + "Refer to the https://ahooks.js.org/hooks/dom/use-external/#options");
    }

    if (!ref.current) return; // Bind setAttribute Event

    var setAttributeFromEvent = function setAttributeFromEvent(event) {
      var _a;

      (_a = ref.current) === null || _a === void 0 ? void 0 : _a.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
    };

    ref.current.addEventListener('load', setAttributeFromEvent);
    ref.current.addEventListener('error', setAttributeFromEvent);

    var setStateFromEvent = function setStateFromEvent(event) {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };

    ref.current.addEventListener('load', setStateFromEvent);
    ref.current.addEventListener('error', setStateFromEvent);
    return function () {
      var _a, _b;

      (_a = ref.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('load', setStateFromEvent);
      (_b = ref.current) === null || _b === void 0 ? void 0 : _b.removeEventListener('error', setStateFromEvent);
    };
  }, [path, active]);
  var action = useMemo(function () {
    var unload = function unload() {
      return setActive(false);
    };

    var load = function load() {
      return setActive(true);
    };

    var toggle = function toggle() {
      return setActive(function (value) {
        return !value;
      });
    };

    return {
      toggle: toggle,
      load: load,
      unload: unload
    };
  }, [setActive]);
  return [status, action];
}