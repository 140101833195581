var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
      m = s && o[s],
      i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function next() {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};

import { useEffect, useState } from 'react';
import 'intersection-observer';
import { getTargetElement } from '../utils/dom';

function isInViewPort(el) {
  if (!el) {
    return undefined;
  }

  var viewPortWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  var viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  var rect = el.getBoundingClientRect();

  if (rect) {
    var top_1 = rect.top,
        bottom = rect.bottom,
        left = rect.left,
        right = rect.right;
    return bottom > 0 && top_1 <= viewPortHeight && left <= viewPortWidth && right > 0;
  }

  return false;
}

function useInViewport(target) {
  var _a = __read(useState(function () {
    var el = getTargetElement(target);
    return isInViewPort(el);
  }), 2),
      inViewPort = _a[0],
      setInViewport = _a[1];

  useEffect(function () {
    var el = getTargetElement(target);

    if (!el) {
      return function () {};
    }

    var observer = new IntersectionObserver(function (entries) {
      var e_1, _a;

      try {
        for (var entries_1 = __values(entries), entries_1_1 = entries_1.next(); !entries_1_1.done; entries_1_1 = entries_1.next()) {
          var entry = entries_1_1.value;

          if (entry.isIntersecting) {
            setInViewport(true);
          } else {
            setInViewport(false);
          }
        }
      } catch (e_1_1) {
        e_1 = {
          error: e_1_1
        };
      } finally {
        try {
          if (entries_1_1 && !entries_1_1.done && (_a = entries_1["return"])) _a.call(entries_1);
        } finally {
          if (e_1) throw e_1.error;
        }
      }
    });
    observer.observe(el);
    return function () {
      observer.disconnect();
    };
  }, [target]);
  return inViewPort;
}

export default useInViewport;