/* eslint no-empty: 0 */
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

import { useCallback, useRef, useState } from 'react';
import screenfull from 'screenfull';
import useUnmount from '../useUnmount';
import { getTargetElement } from '../utils/dom';
export default (function (target, options) {
  var _a = options || {},
      onExitFull = _a.onExitFull,
      onFull = _a.onFull;

  var onExitFullRef = useRef(onExitFull);
  onExitFullRef.current = onExitFull;
  var onFullRef = useRef(onFull);
  onFullRef.current = onFull;

  var _b = __read(useState(false), 2),
      state = _b[0],
      setState = _b[1];

  var onChange = useCallback(function () {
    if (screenfull.isEnabled) {
      var isFullscreen = screenfull.isFullscreen;

      if (isFullscreen) {
        onFullRef.current && onFullRef.current();
      } else {
        screenfull.off('change', onChange);
        onExitFullRef.current && onExitFullRef.current();
      }

      setState(isFullscreen);
    }
  }, []);
  var setFull = useCallback(function () {
    var el = getTargetElement(target);

    if (!el) {
      return;
    }

    if (screenfull.isEnabled) {
      try {
        screenfull.request(el);
        screenfull.on('change', onChange);
      } catch (error) {}
    }
  }, [target, onChange]);
  var exitFull = useCallback(function () {
    if (!state) {
      return;
    }

    if (screenfull.isEnabled) {
      screenfull.exit();
    }
  }, [state]);
  var toggleFull = useCallback(function () {
    if (state) {
      exitFull();
    } else {
      setFull();
    }
  }, [state, setFull, exitFull]);
  useUnmount(function () {
    if (screenfull.isEnabled) {
      screenfull.off('change', onChange);
    }
  });
  return [state, {
    setFull: setFull,
    exitFull: exitFull,
    toggleFull: toggleFull
  }];
});