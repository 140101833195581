var useDrag = function useDrag(config) {
  var getProps = function getProps(data) {
    return {
      key: JSON.stringify(data),
      draggable: 'true',
      onDragStart: function onDragStart(e) {
        if (config && config.onDragStart) {
          config.onDragStart(data, e);
        }

        e.dataTransfer.setData('custom', JSON.stringify(data));
      },
      onDragEnd: function onDragEnd(e) {
        if (config && config.onDragEnd) {
          config.onDragEnd(data, e);
        }
      }
    };
  };

  return getProps;
};

export default useDrag;