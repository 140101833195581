var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};
/* eslint-disable no-shadow */


import { useState, useMemo } from 'react';
export default function useSelections(items, defaultSelected) {
  if (defaultSelected === void 0) {
    defaultSelected = [];
  }

  var _a = __read(useState(defaultSelected), 2),
      selected = _a[0],
      setSelected = _a[1];

  var selectedSet = useMemo(function () {
    return new Set(selected);
  }, [selected]);
  var singleActions = useMemo(function () {
    var isSelected = function isSelected(item) {
      return selectedSet.has(item);
    };

    var select = function select(item) {
      selectedSet.add(item);
      return setSelected(Array.from(selectedSet));
    };

    var unSelect = function unSelect(item) {
      selectedSet["delete"](item);
      return setSelected(Array.from(selectedSet));
    };

    var toggle = function toggle(item) {
      if (isSelected(item)) {
        unSelect(item);
      } else {
        select(item);
      }
    };

    return {
      isSelected: isSelected,
      select: select,
      unSelect: unSelect,
      toggle: toggle
    };
  }, [selectedSet]);
  var allActions = useMemo(function () {
    var selectAll = function selectAll() {
      items.forEach(function (o) {
        selectedSet.add(o);
      });
      setSelected(Array.from(selectedSet));
    };

    var unSelectAll = function unSelectAll() {
      items.forEach(function (o) {
        selectedSet["delete"](o);
      });
      setSelected(Array.from(selectedSet));
    };

    var noneSelected = items.every(function (o) {
      return !selectedSet.has(o);
    });
    var allSelected = items.every(function (o) {
      return selectedSet.has(o);
    }) && !noneSelected;
    var partiallySelected = !noneSelected && !allSelected;

    var toggleAll = function toggleAll() {
      return allSelected ? unSelectAll() : selectAll();
    };

    return {
      selectAll: selectAll,
      unSelectAll: unSelectAll,
      noneSelected: noneSelected,
      allSelected: allSelected,
      partiallySelected: partiallySelected,
      toggleAll: toggleAll
    };
  }, [selectedSet, items]);
  return __assign(__assign({
    selected: selected,
    setSelected: setSelected
  }, singleActions), allActions);
}