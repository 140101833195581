var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

var __spread = this && this.__spread || function () {
  for (var ar = [], i = 0; i < arguments.length; i++) {
    ar = ar.concat(__read(arguments[i]));
  }

  return ar;
};

import useRequest from '@ahooksjs/use-request';
import { useState, useCallback, useEffect, useRef } from 'react';
import useUpdateEffect from '../useUpdateEffect';
import usePersistFn from '../usePersistFn';

function useAntdTable(service, options) {
  var form = options.form,
      _a = options.refreshDeps,
      refreshDeps = _a === void 0 ? [] : _a,
      manual = options.manual,
      _b = options.defaultType,
      defaultType = _b === void 0 ? 'simple' : _b,
      defaultParams = options.defaultParams,
      restOptions = __rest(options, ["form", "refreshDeps", "manual", "defaultType", "defaultParams"]);

  var result = useRequest(service, __assign(__assign({}, restOptions), {
    paginated: true,
    manual: true
  }));
  var params = result.params,
      run = result.run;
  var cacheFormTableData = params[2] || {}; // 优先从缓存中读

  var _c = __read(useState(cacheFormTableData.type || defaultType), 2),
      type = _c[0],
      setType = _c[1]; // 全量 form 数据，包括 simple 和 advance


  var _d = __read(useState(cacheFormTableData.allFormData || defaultParams && defaultParams[1] || {}), 2),
      allFormData = _d[0],
      setAllFormData = _d[1]; // 获取当前展示的 form 字段值


  var getActivetFieldValues = useCallback(function () {
    if (!form) {
      return {};
    } // antd 3


    if (form.getFieldInstance) {
      var tempAllFiledsValue_1 = form.getFieldsValue();
      var filterFiledsValue_1 = {};
      Object.keys(tempAllFiledsValue_1).forEach(function (key) {
        if (form.getFieldInstance ? form.getFieldInstance(key) : true) {
          filterFiledsValue_1[key] = tempAllFiledsValue_1[key];
        }
      });
      return filterFiledsValue_1;
    } // antd 4


    return form.getFieldsValue(null, function () {
      return true;
    });
  }, [form]);
  var formRef = useRef(form);
  formRef.current = form;
  /* 初始化，或改变了 searchType, 恢复表单数据 */

  useEffect(function () {
    if (!formRef.current) {
      return;
    } // antd 3


    if (formRef.current.getFieldInstance) {
      // antd 3 需要判断字段是否存在，否则会抛警告
      var filterFiledsValue_2 = {};
      Object.keys(allFormData).forEach(function (key) {
        if (formRef.current.getFieldInstance ? formRef.current.getFieldInstance(key) : true) {
          filterFiledsValue_2[key] = allFormData[key];
        }
      });
      formRef.current.setFieldsValue(filterFiledsValue_2);
    } else {
      // antd 4
      formRef.current.setFieldsValue(allFormData);
    }
  }, [type]); // 首次加载，手动提交。为了拿到 form 的 initial values

  useEffect(function () {
    // 如果有缓存，则使用缓存，重新请求
    if (params.length > 0) {
      run.apply(void 0, __spread(params));
      return;
    } // 如果没有缓存，触发 submit


    if (!manual) {
      _submit(defaultParams);
    }
  }, []);
  var changeType = useCallback(function () {
    var currentFormData = getActivetFieldValues();
    setAllFormData(__assign(__assign({}, allFormData), currentFormData));
    var targetType = type === 'simple' ? 'advance' : 'simple';
    setType(targetType);
  }, [type, allFormData, getActivetFieldValues]);
  var validateFields = useCallback(function () {
    var fieldValues = getActivetFieldValues();

    if (!form) {
      return Promise.resolve();
    }

    var fields = Object.keys(fieldValues);

    if (!form.getInternalHooks) {
      return new Promise(function (resolve, reject) {
        form.validateFields(fields, function (errors, values) {
          if (errors) {
            reject(errors);
          } else {
            resolve(values);
          }
        });
      });
    }

    return form.validateFields(fields);
  }, [form]);

  var _submit = useCallback(function (initParams) {
    setTimeout(function () {
      validateFields().then(function () {
        var activeFormData = getActivetFieldValues(); // 记录全量数据

        var _allFormData = __assign(__assign({}, allFormData), activeFormData);

        setAllFormData(_allFormData); // has defaultParams

        if (initParams) {
          run(initParams[0], activeFormData, {
            allFormData: _allFormData,
            type: type
          });
          return;
        }

        run(__assign(__assign({
          pageSize: options.defaultPageSize || 10
        }, params[0] || {}), {
          current: 1
        }), activeFormData, {
          allFormData: _allFormData,
          type: type
        });
      })["catch"](function (err) {
        return err;
      });
    });
  }, [getActivetFieldValues, run, params, allFormData, type]);

  var reset = useCallback(function () {
    if (form) {
      form.resetFields();
    }

    _submit();
  }, [form, _submit]);
  var resetPersistFn = usePersistFn(reset); // refreshDeps 变化，reset。

  useUpdateEffect(function () {
    if (!manual) {
      resetPersistFn();
    }
  }, __spread(refreshDeps));
  var submit = usePersistFn(function (e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    _submit();
  });
  return __assign(__assign({}, result), {
    search: {
      submit: submit,
      type: type,
      changeType: changeType,
      reset: reset
    }
  });
}

export default useAntdTable;