var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};

import { useMemo, useState, useRef, useCallback } from 'react';

var getProps = function getProps(callback, setIsHovering) {
  return {
    onDragOver: function onDragOver(event) {
      event.preventDefault();
    },
    onDragEnter: function onDragEnter(event) {
      event.preventDefault();
      setIsHovering(true);
    },
    onDragLeave: function onDragLeave() {
      setIsHovering(false);
    },
    onDrop: function onDrop(event) {
      event.preventDefault();
      event.persist();
      setIsHovering(false);
      callback(event.dataTransfer, event);
    },
    onPaste: function onPaste(event) {
      event.persist();
      callback(event.clipboardData, event);
    }
  };
};

var useDrop = function useDrop(options) {
  if (options === void 0) {
    options = {};
  }

  var optionsRef = useRef(options);
  optionsRef.current = options;

  var _a = __read(useState(false), 2),
      isHovering = _a[0],
      setIsHovering = _a[1];

  var callback = useCallback(function (dataTransfer, event) {
    var uri = dataTransfer.getData('text/uri-list');
    var dom = dataTransfer.getData('custom');

    if (dom && optionsRef.current.onDom) {
      var data = dom;

      try {
        data = JSON.parse(dom);
      } catch (e) {
        data = dom;
      }

      optionsRef.current.onDom(data, event);
      return;
    }

    if (uri && optionsRef.current.onUri) {
      optionsRef.current.onUri(uri, event);
      return;
    }

    if (dataTransfer.files && dataTransfer.files.length && optionsRef.current.onFiles) {
      optionsRef.current.onFiles(Array.from(dataTransfer.files), event);
      return;
    }

    if (dataTransfer.items && dataTransfer.items.length && optionsRef.current.onText) {
      dataTransfer.items[0].getAsString(function (text) {
        optionsRef.current.onText(text, event);
      });
    }
  }, []);
  var props = useMemo(function () {
    return getProps(callback, setIsHovering);
  }, [callback, setIsHovering]);
  return [props, {
    isHovering: isHovering
  }];
};

export default useDrop;