var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
      r,
      ar = [],
      e;

  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) {
      ar.push(r.value);
    }
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }

  return ar;
};
/* eslint-disable max-len */


import { useState, useMemo, useCallback } from 'react';

function useSet(initialValue) {
  var initialSet = useMemo(function () {
    return initialValue === undefined ? new Set() : new Set(initialValue);
  }, []);

  var _a = __read(useState(initialSet), 2),
      set = _a[0],
      setSet = _a[1];

  var stableActions = useMemo(function () {
    return {
      add: function add(key) {
        setSet(function (prevSet) {
          var temp = new Set(prevSet);
          temp.add(key);
          return temp;
        });
      },
      remove: function remove(key) {
        setSet(function (prevSet) {
          var temp = new Set(prevSet);
          temp["delete"](key);
          return temp;
        });
      },
      reset: function reset() {
        return setSet(initialSet);
      }
    };
  }, [setSet, initialSet]);

  var utils = __assign({
    has: useCallback(function (key) {
      return set.has(key);
    }, [set])
  }, stableActions);

  return [set, utils];
}

export default useSet;